export const allGameSets = [
  {
    name: "Animals",
    words: ["Dog", "Cat", "Fish", "Bird"],
    color: "bg-red-200",
  },
  {
    name: "Colors",
    words: ["Red", "Blue", "Green", "Yellow"],
    color: "bg-blue-200",
  },
  {
    name: "Foods",
    words: ["Apple", "Banana", "Carrot", "Bread"],
    color: "bg-green-200",
  },
  {
    name: "Toys",
    words: ["Ball", "Doll", "Car", "Blocks"],
    color: "bg-yellow-200",
  },
  {
    name: "Shapes",
    words: ["Circle", "Square", "Triangle", "Star"],
    color: "bg-purple-200",
  },
  {
    name: "Weather",
    words: ["Sun", "Rain", "Snow", "Wind"],
    color: "bg-orange-200",
  },
  {
    name: "Clothes",
    words: ["Shirt", "Pants", "Shoes", "Hat"],
    color: "bg-pink-200",
  },
  {
    name: "School",
    words: ["Book", "Pencil", "Desk", "Backpack"],
    color: "bg-indigo-200",
  },
  {
    name: "Family",
    words: ["Uncle", "Aunt", "Sister", "Brother"],
    color: "bg-teal-200",
  },
  {
    name: "Vehicles",
    words: ["Car", "Bus", "Bike", "Boat"],
    color: "bg-amber-200",
  },
  {
    name: "Emotions",
    words: ["Happy", "Sad", "Angry", "Excited"],
    color: "bg-lime-200",
  },
  {
    name: "Body Parts",
    words: ["Head", "Hand", "Foot", "Eye"],
    color: "bg-cyan-200",
  },
  {
    name: "Seasons",
    words: ["Spring", "Summer", "Fall", "Winter"],
    color: "bg-rose-200",
  },
  {
    name: "Numbers",
    words: ["One", "Two", "Three", "Four"],
    color: "bg-emerald-200",
  },
  {
    name: "Furniture",
    words: ["Chair", "Table", "Bed", "Sofa"],
    color: "bg-violet-200",
  },
  {
    name: "Fruits",
    words: ["Orange", "Grape", "Peach", "Cherry"],
    color: "bg-fuchsia-200",
  },

  {
    name: "Sports",
    words: ["Soccer", "Tennis", "Swimming", "Running"],
    color: "bg-sky-200",
  },
  {
    name: "Insects",
    words: ["Ant", "Bee", "Fly", "Butterfly"],
    color: "bg-stone-200",
  },
  {
    name: "Plants",
    words: ["Tree", "Flower", "Grass", "Bush"],
    color: "bg-slate-200",
  },
  {
    name: "Kitchen",
    words: ["Fork", "Spoon", "Plate", "Cup"],
    color: "bg-neutral-200",
  },

  {
    name: "Jobs",
    words: ["Doctor", "Teacher", "Chef", "Pilot"],
    color: "bg-red-300",
  },
  {
    name: "Instruments",
    words: ["Guitar", "Piano", "Drum", "Violin"],
    color: "bg-blue-300",
  },
  {
    name: "Countries",
    words: ["France", "Japan", "Brazil", "Egypt"],
    color: "bg-green-300",
  },
  {
    name: "Languages",
    words: ["English", "Spanish", "Chinese", "Arabic"],
    color: "bg-yellow-300",
  },

  {
    name: "Planets",
    words: ["Earth", "Mars", "Venus", "Jupiter"],
    color: "bg-purple-300",
  },
  {
    name: "Ocean",
    words: ["Whale", "Shark", "Coral", "Seaweed"],
    color: "bg-orange-300",
  },
  {
    name: "Art",
    words: ["Paint", "Sculpture", "Canvas", "Brush"],
    color: "bg-indigo-300",
  },

  {
    name: "Technology",
    words: ["Computer", "Phone", "Robot", "Tablet"],
    color: "bg-teal-300",
  },
  {
    name: "Desserts",
    words: ["Cake", "Ice Cream", "Cookies", "Pie"],
    color: "bg-amber-300",
  },
  {
    name: "Safari Animals",
    words: ["Lion", "Elephant", "Giraffe", "Monkey"],
    color: "bg-lime-300",
  },
  {
    name: "Buildings",
    words: ["House", "Building", "Castle", "School"],
    color: "bg-cyan-300",
  },

  {
    name: "Farm",
    words: ["Cow", "Pig", "Chicken", "Sheep"],
    color: "bg-rose-300",
  },
  {
    name: "Drinks",
    words: ["Water", "Milk", "Juice", "Tea"],
    color: "bg-emerald-300",
  },
  {
    name: "Vegetables",
    words: ["Tomato", "Potato", "Broccoli", "Corn"],
    color: "bg-violet-300",
  },

  {
    name: "Dinosaurs",
    words: ["T-Rex", "Triceratops", "Stegosaurus", "Raptor"],
    color: "bg-sky-300",
  },
  {
    name: "Fairy Tales",
    words: ["Princess", "Dragon", "Wizard", "Unicorn"],
    color: "bg-stone-300",
  },
  {
    name: "Music",
    words: ["Sing", "Dance", "Clap", "Listen"],
    color: "bg-slate-300",
  },
  {
    name: "Pets",
    words: ["Hamster", "Goldfish", "Dog", "Cat"],
    color: "bg-neutral-300",
  },

  {
    name: "Helpers",
    words: ["Firefighter", "Police", "Nurse", "Teacher"],
    color: "bg-red-400",
  },
  {
    name: "Transportation",
    words: ["Train", "Airplane", "Helicopter", "Ship"],
    color: "bg-blue-400",
  },
  {
    name: "Nature",
    words: ["River", "Mountain", "Forest", "Beach"],
    color: "bg-green-400",
  },
  {
    name: "Time",
    words: ["Clock", "Minutes", "Hours", "Seconds"],
    color: "bg-yellow-400",
  },
  {
    name: "Basic Math",
    words: ["Add", "Subtract", "Plus", "Minus"],
    color: "bg-brown-200",
  },
  {
    name: "Time Words",
    words: ["Yesterday", "Today", "Tomorrow", "Now"],
    color: "bg-black-200",
  },
  {
    name: "2D Shapes",
    words: ["Circle", "Square", "Triangle", "Rectangle"],
    color: "bg-red-200",
  },
  {
    name: "3D Shapes",
    words: ["Cube", "Sphere", "Cone", "Cylinder"],
    color: "bg-green-300",
  },
  {
    name: "Coins",
    words: ["Penny", "Nickel", "Dime", "Quarter"],
    color: "bg-yellow-200",
  },
  {
    name: "Story Elements",
    words: ["Character", "Plot", "Beginning", "End"],
    color: "bg-orange-200",
  },
  {
    name: "Rhymes",
    words: ["Cat", "Hat", "Bat", "Rat"],
    color: "bg-purple-300",
  },
  {
    name: "Geometric Shapes",
    words: ["Pentagon", "Hexagon", "Octagon", "Decagon"],
    color: "bg-purple-600",
  },
  {
    name: "World Oceans",
    words: ["Atlantic", "Pacific", "Indian", "Arctic"],
    color: "bg-blue-700",
  },
  {
    name: "Human Anatomy",
    words: ["Heart", "Lungs", "Brain", "Liver"],
    color: "bg-red-700",
  },
  {
    name: "Types of Rocks",
    words: ["Granite", "Marble", "Limestone", "Sandstone"],
    color: "bg-gray-400",
  },
  {
    name: "Continents",
    words: ["Asia", "Africa", "Europe", "South America"],
    color: "bg-yellow-300",
  },
  {
    name: "Kitchen Items",
    words: ["Oven", "Refrigerator", "Microwave", "Sink"],
    color: "bg-gray-300",
  },
  {
    name: "Superheroes",
    words: ["Batman", "Superman", "Wonder Woman", "Spider-Man"],
    color: "bg-red-400",
  },
  {
    name: "Types of Birds",
    words: ["Eagle", "Parrot", "Penguin", "Owl"],
    color: "bg-blue-700",
  },
  {
    name: "Musical Genres",
    words: ["Rock", "Jazz", "Classical", "Pop"],
    color: "bg-blue-600",
  },
  {
    name: "Months",
    words: ["January", "February", "March", "April"],
    color: "bg-pink-300",
  },
  {
    name: "Household Items",
    words: ["Spoon", "Fork", "Knife", "Plate"],
    color: "bg-gray-400",
  },
  {
    name: "Ocean Creatures",
    words: ["Shark", "Dolphin", "Whale", "Octopus"],
    color: "bg-blue-400",
  },
   {
    name: "Super Mario",
    words: ["Mario", "Luigi", "Peach", "Bowser"],
    color: "bg-pink-300",
  },
    {
    name: "Playground",
    words: ["Slide", "Swing", "Rock Wall", "Monkey Bars"],
    color: "bg-blue-600",
  },
  
  

];

