import React, { useState, useCallback, useEffect } from "react";
import { allGameSets } from "../data/gameSets";

const Button = ({ children, style, onClick }) => (
  <button
    style={{
      padding: "0.5rem",
      borderRadius: "0.5rem",
      fontWeight: "bold",
      fontSize: "1rem",
      transition: "background-color 0.2s",
      cursor: "pointer",
      border: "none",
      width: "100%",
      height: "100%",
      minHeight: "3rem",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...style,
    }}
    onClick={onClick}
  >
    {children}
  </button>
);

const Card = ({ children, style }) => (
  <div
    style={{
      backgroundColor: "white",
      borderRadius: "0.5rem",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "1rem",
      maxWidth: "100%",
      width: "100%",
      boxSizing: "border-box",
      margin: "0 auto",
      ...style,
    }}
  >
    {children}
  </div>
);

const CardTitle = ({ children }) => (
  <h1
    style={{
      fontSize: "clamp(1.5rem, 5vw, 2.5rem)",
      fontWeight: "bold",
      marginBottom: "1rem",
      textAlign: "center",
    }}
  >
    {children}
  </h1>
);

const Alert = ({ children, style, onClose }) => (
  <div
    style={{
      padding: "1rem",
      borderRadius: "0.375rem",
      backgroundColor: "#F3F4F6",
      color: "#111827",
      marginBottom: "1rem",
      fontSize: "1rem",
      position: "relative",
      ...style,
    }}
  >
    {children}
    {onClose && (
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "0.5rem",
          right: "0.5rem",
          background: "none",
          border: "none",
          fontSize: "1rem",
          cursor: "pointer",
          color: "inherit",
        }}
      >
        ×
      </button>
    )}
  </div>
);

const Popup = ({ children, onClose }) => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }}
  >
    <div
      style={{
        backgroundColor: "white",
        padding: "2rem",
        borderRadius: "0.5rem",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        position: "relative",
        maxWidth: "90%",
        maxHeight: "90%",
        overflow: "auto",
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "0.5rem",
          right: "0.5rem",
          background: "none",
          border: "none",
          fontSize: "1.5rem",
          cursor: "pointer",
        }}
      >
        ×
      </button>
      {children}
    </div>
  </div>
);

const MatchWithWordsGame = () => {
  const shuffleArray = useCallback((array) => {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }, []);

  const getRandomGameSet = useCallback(() => {
    const shuffledSets = shuffleArray([...allGameSets]);
    return shuffledSets.slice(0, 4);
  }, [shuffleArray]);

  const initializeGame = useCallback(() => {
    const randomGameSet = getRandomGameSet();
    const allWords = randomGameSet.flatMap((category) => category.words);
    return {
      categories: randomGameSet,
      words: shuffleArray([...allWords]),
      selectedWords: [],
      solvedCategories: [],
      message: "",
      mistakes: 0,
    };
  }, [getRandomGameSet, shuffleArray]);

  const [gameState, setGameState] = useState(initializeGame);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showPopup, setShowPopup] = useState(false);
  const [hint, setHint] = useState("");

  const {
    categories,
    words,
    selectedWords,
    solvedCategories,
    message,
    mistakes,
  } = gameState;

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleWordClick = useCallback((word) => {
    setGameState((prevState) => ({
      ...prevState,
      selectedWords: prevState.selectedWords.includes(word)
        ? prevState.selectedWords.filter((w) => w !== word)
        : prevState.selectedWords.length < 4
        ? [...prevState.selectedWords, word]
        : prevState.selectedWords,
    }));
  }, []);

  const checkMatches = useCallback(() => {
    if (selectedWords.length !== 4) {
      setGameState((prevState) => ({
        ...prevState,
        message: "Please select 4 words.",
      }));
      return;
    }

    const category = categories.find((cat) =>
      cat.words.every((word) => selectedWords.includes(word))
    );

    if (category) {
      setGameState((prevState) => ({
        ...prevState,
        message: `Great job! You found the ${category.name} group.`,
        solvedCategories: [...prevState.solvedCategories, category],
        words: prevState.words.filter((word) => !category.words.includes(word)),
        selectedWords: [],
      }));

      if (solvedCategories.length + 1 === categories.length) {
        setShowPopup(true);
      }
    } else {
      setGameState((prevState) => ({
        ...prevState,
        message: "Try again! These words don't belong together.",
        mistakes: prevState.mistakes + 1,
        selectedWords: [],
      }));
    }
  }, [categories, selectedWords, solvedCategories.length]);

  useEffect(() => {
    if (mistakes >= 4) {
      setGameState((prevState) => ({
        ...prevState,
        message:
          'Game over! You made 4 mistakes. Click "New Game" to try again.',
      }));
    }
  }, [mistakes]);

  const shuffleWords = useCallback(() => {
    setGameState((prevState) => ({
      ...prevState,
      words: shuffleArray([...prevState.words]),
    }));
  }, [shuffleArray]);

  const startNewGame = useCallback(() => {
    setGameState(initializeGame());
    setHint("");
  }, [initializeGame]);

  const getHint = useCallback(() => {
    const remainingCategories = categories.filter(
      (cat) => !solvedCategories.includes(cat)
    );
    if (remainingCategories.length > 0) {
      const randomCategory =
        remainingCategories[
          Math.floor(Math.random() * remainingCategories.length)
        ];
      setHint(`Try looking for words related to ${randomCategory.name}.`);
    } else {
      setHint("No more hints available. You're almost done!");
    }
  }, [categories, solvedCategories]);

  const closeHint = useCallback(() => {
    setHint("");
  }, []);

  const colorMap = {
    "bg-red-200": "#FED7D7",
    "bg-blue-200": "#BFDBFE",
    "bg-green-200": "#A7F3D0",
    "bg-yellow-200": "#FDE68A",
    "bg-purple-200": "#DDD6FE",
    "bg-orange-200": "#FED7AA",
    "bg-pink-200": "#FBCFE8",
    "bg-indigo-200": "#C7D2FE",
  };

  const isMobile = windowWidth <= 640;

  return (
    <Card style={{ maxWidth: isMobile ? "100%" : "800px" }}>
      <CardTitle>Match with Words</CardTitle>
      <p
        style={{
          marginBottom: "1rem",
          textAlign: "center",
          fontSize: "clamp(1rem, 3vw, 1.5rem)",
        }}
      >
        Match four words in the same category. Have fun!
      </p>
      {words.length > 0 ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
            gap: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          {words.map((word) => (
            <Button
              key={word}
              onClick={() => handleWordClick(word)}
              style={{
                backgroundColor: selectedWords.includes(word)
                  ? "#4B5563"
                  : "#F3F4F6",
                color: selectedWords.includes(word) ? "white" : "#111827",
                fontSize: isMobile ? "0.875rem" : "1.25rem",
              }}
            >
              {word}
            </Button>
          ))}
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          onClick={checkMatches}
          style={{
            flex: 1,
            backgroundColor: "#8B5CF6",
            color: "white",
          }}
        >
          Check Matches
        </Button>
        <Button
          onClick={shuffleWords}
          style={{
            flex: 1,
            backgroundColor: "#3B82F6",
            color: "white",
          }}
        >
          Shuffle Words
        </Button>
        <Button
          onClick={getHint}
          style={{
            flex: 1,
            backgroundColor: "#10B981",
            color: "white",
          }}
        >
          Hint
        </Button>
      </div>
      <div style={{ marginBottom: "1rem", fontSize: "1rem" }}>
        <strong>Mistakes remaining:</strong> {4 - mistakes}
      </div>
      {message && <Alert>{message}</Alert>}
      {hint && (
        <Alert
          style={{ backgroundColor: "#D1FAE5", color: "#065F46" }}
          onClose={closeHint}
        >
          Hint: {hint}
        </Alert>
      )}
      {solvedCategories.map((category, index) => {
        const backgroundColor =
          colorMap[category.color] ||
          Object.values(colorMap)[index % Object.values(colorMap).length];
        return (
          <div
            key={index}
            style={{
              backgroundColor,
              padding: "0.75rem",
              marginBottom: "0.5rem",
              borderRadius: "0.5rem",
              color: "#111827",
              fontSize: "1rem",
            }}
          >
            <strong>{category.name}:</strong> {category.words.join(", ")}
          </div>
        );
      })}
      <Button
        onClick={startNewGame}
        style={{
          backgroundColor: "#F97316",
          color: "white",
          marginTop: "1rem",
          fontSize: "1.25rem",
          padding: "1rem",
        }}
      >
        New Game
      </Button>
      {showPopup && (
        <Popup onClose={() => setShowPopup(false)}>
          <h2 style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
            Great job! 🥳🦄🎉
          </h2>
          <p>You've completed the game!</p>
        </Popup>
      )}
    </Card>
  );
};

export default MatchWithWordsGame;
